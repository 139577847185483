<!-- eslint-disable no-alert -->
<template>
  <div>
    <h1>Smart proposals AI extraction settings</h1>
    <div class="el-table-container">
      <ElAlert
        type="info"
        show-icon
        :closable="false"
      >
        Manage the use of DIVE-extracted plan design data for each product. Changes apply to all carriers and will be <em>immediately</em> reflected in the Smart Proposals feature.
      </ElAlert>

      <ElSelect v-model="selectedProductTypeId" placeholder="Select product" @change="handleProductTypeChange">
        <ElOption v-for="product in diveProductTypes" :key="product.id" :label="product.name" :value="product.id" />
      </ElSelect>
      <ElTable
        v-loading="loading"
        :data="productTypeAttributes"
        border
        style="width: 100%"
        :empty-text="selectedProductTypeId ? 'No attributes available for this product' : 'Select a product to see attributes'"
      >
        <ElTableColumn label="Category" width="250">
          <template #default="{ row }">
            {{ row.categoryName }}
          </template>
        </ElTableColumn>
        <ElTableColumn label="Plan Attribute">
          <template #default="{ row }">
            {{ row.attributeName }}
          </template>
        </ElTableColumn>
        <ElTableColumn label="Enabled" width="130">
          <template #default="{ row }">
            <ElSwitch v-model="row.enabled" data-test="dive enabled toggle" @change="toggleExclusion(row)" />
          </template>
        </ElTableColumn>
      </ElTable>
    </div>
  </div>
</template>

<script>
  import { getProductTypeAttributes as getProductTypeAttributesApi } from '@/services/normalizedValues.js';
  import { getProductTypes, getProductTypePlanDesignAttributeDiveExclusions, updatePlanDesignAttributeDiveExclusion } from '@/services/product.js';

  export default {
    data() {
      return {
        loading: true,
        selectedProductTypeId: null,
        allProductTypes: [],
        productDetailsCache: {},
        exclusions: [],
        /* The following two lists are currently duplicated from Carrier UI utils/product.js and should,
         * if this feature lives on long enough, be moved to the BE.
         */
        /**
         * List of product types that are not supported by dive, but ARE supported by ThreeFlow Assist
         * This list supports both strings and regex; if a string is passed in, it will be compared case-insensitively
         */
        diveUnsupportedProductTypes: [
          'stop loss',
          'critical illness',
          /paid leave/,
        ],

        /**
         * List of product types that are not supported by dive OR ThreeFlow Assist
         */
        tfAssistAndDiveUnsupportedProductTypes: [
          'absence management',
        ],
      };
    },
    computed: {
      /**
       * Displayed attributes for the selected product type
       *
       * @returns {Array}
       */
      productTypeAttributes() {
        if (!this.selectedProductTypeId || !this.productDetailsCache[this.selectedProductTypeId]) return [];

        const productDetails = this.productDetailsCache[this.selectedProductTypeId];
        const sortedCategories = [...productDetails].sort(
          (a, b) => a.category_order - b.category_order,
        );
        const attributes = sortedCategories.flatMap((category) => category.plan_design_attributes
          .sort((a, b) => a.position - b.position)
          .map((attr) => ({
            attributeId: attr.id,
            attributeName: attr.name,
            categoryName: category.name,
            enabled: !this.exclusions.some((item) => item.product_type_id === this.selectedProductTypeId && item.attribute_id === attr.id),
          })));

        return attributes;
      },
      /**
       * Filter out product types that are not supported by DIVE
       *
       * @returns {Array}
       */
      diveProductTypes() {
        const unsupportedTypes = [
          ...this.diveUnsupportedProductTypes,
          ...this.tfAssistAndDiveUnsupportedProductTypes,
        ];
        const diveProductTypes = this.allProductTypes.filter((productType) => {
          const lowercaseName = productType?.name.toLowerCase();

          return !unsupportedTypes.some((type) => {
            if (type instanceof RegExp) {
              return type.test(lowercaseName);
            }

            return lowercaseName === type;
          });
        });

        return diveProductTypes;
      },
    },
    async created() {
      this.allProductTypes = (await getProductTypes())?.product_types || [];
      if (this.diveProductTypes.length > 0) {
        this.selectedProductTypeId = this.diveProductTypes[0].id;
        await this.handleProductTypeChange(this.selectedProductTypeId);
        await this.getAndSetProductTypeExclusions(this.selectedProductTypeId);
      }
      this.loading = false;
    },
    methods: {
      /**
       * Get plan design attribute DIVE exclusions by product type id
       * and then set them locally
       *
       * @param {number} productTypeId
       */
      async getAndSetProductTypeExclusions(productTypeId) {
        const { plan_design_attributes } = await getProductTypePlanDesignAttributeDiveExclusions(productTypeId);

        this.exclusions = plan_design_attributes
          .filter((attr) => attr.dive_excluded)
          .map((attr) => ({
            product_type_id: productTypeId,
            attribute_id: attr.plan_design_attribute_id,
          }));
      },
      /**
       * Handle product type change from ElSelect
       *
       * @param {number} productTypeId
       */
      async handleProductTypeChange(productTypeId) {
        this.exclusions = [];

        if (!this.productDetailsCache[productTypeId]) {
          this.loading = true;
          this.$set(this.productDetailsCache, productTypeId, await getProductTypeAttributesApi(productTypeId));
        }
        await this.getAndSetProductTypeExclusions(productTypeId);
        this.loading = false;
      },
      /**
       * Toggle the exclusion of a product type attribute
       *
       * @param {object} row
       */
      async toggleExclusion(row) {
        let message;
        const isCurrentlyEnabled = row.enabled;

        try {
          await updatePlanDesignAttributeDiveExclusion(
            this.selectedProductTypeId,
            row.attributeId,
            !isCurrentlyEnabled,
          );

          if (isCurrentlyEnabled) {
            // Switch is ON, we want to remove from exclusions array
            const index = this.exclusions.findIndex(
              (item) => item.product_type_id === this.selectedProductTypeId && item.attribute_id === row.attributeId,
            );

            this.exclusions.splice(index, 1);
            message = `Attribute "${row.categoryName} / ${row.attributeName}" will be included in DIVE.`;
          } else {
            // Switch is OFF, we want to add to exclusions array
            this.exclusions.push({
              product_type_id: this.selectedProductTypeId,
              attribute_id: row.attributeId,
            });
            message = `Attribute "${row.categoryName} / ${row.attributeName}" will be excluded from DIVE.`;
          }

          this.$message({
            showClose: false,
            message,
            type: 'success',
            duration: 2000,
          });
        } catch (error) {
          this.$message({
            showClose: false,
            message: 'Failed to include or exclude attribute from DIVE.',
            type: 'error',
            duration: 2000,
          });
        }
      },
    },
  };
</script>

<style scoped>
.el-table-container {
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto 48px;
}

.el-alert {
  width: 100%;
  margin-bottom: 32px;
}

h1 {
  background-color: var(--tf-gray-light);
  padding: 10px 30px;
  border-bottom: 1px solid var(--tf-gray-medium);
}

.el-select {
  margin-bottom: 15px;
  width: 100%;
}
</style>
