<template>
  <div>
    <ElDialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      title="Customized Excel Format Tool"
      :before-close="handleClose"
    >
      <div class="description">
        <p>Select a broker and any of the subroutines below to be included in the macro.</p>
      </div>
      <div class="selection-content">
        <div class="dropdown-container">
          <ElSelect
            v-model="selectedBroker"
            no-match-text="Broker not found"
            placeholder="Select broker"
            filterable
            autocomplete
          >
            <ElOption
              v-for="(broker, index) in brokers"
              :key="broker + index"
              :value="broker"
            />
          </ElSelect>
        </div>
        <div
          v-for="(routine, index) in subroutines"
          :key="routine + index"
        >
          <ElCheckbox
            v-model="selectedSubroutines"
            :label="routine.name"
            class="checkbox-container"
          />
          <div
            v-if="selectedSubroutines.includes(routine.name) && subroutineVariables(routine.name).length > 0"
            class="variable-container"
          >
            <ElForm class="variable-form">
              <ElFormItem
                v-for="variable in subroutineVariables(routine.name)"
                :key="variable"
                :label="variable"
                class="form-item"
              >
                <ElInput
                  v-if="!routine.boolean"
                  v-model="formData[`${variable}_${routine.name}`]"
                />
                <ElRadioGroup
                  v-else
                  v-model="formData[`${variable}_${routine.name}`]"
                >
                  <ElRadio :label="true">
                    True
                  </ElRadio>
                  <ElRadio :label="false">
                    False
                  </ElRadio>
                </ElRadioGroup>
              </ElFormItem>
            </ElForm>
          </div>
        </div>
      </div>
      <template #footer>
        <AppButton
          size="text"
          type="primary"
          text="Close"
          @click="handleClose"
        />
        <AppButton
          type="primary"
          text="Export"
          :is-disabled="isDisabled"
          @click="handleExport"
        />
      </template>
    </ElDialog>
  </div>
</template>

<script>
  import subroutines from '@/config/supportRequests/subroutinesConfig.js';
  import { startSubroutines, endSubroutines } from '@/config/supportRequests/mandatorySubroutines.js';
  import { makeRequest } from '@/services/supportService.js';

  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedSubroutines: [],
        selectedBroker: '',
        formData: {},
        subroutines,
        brokers: [],
      };
    },
    computed: {
      visible: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      /**
       * Disable the 'export' button if any of the selected subroutines don't have entered values
       * or a broker is not selected
       *
       * @returns {boolean} value that determines whether the user can export the file
       */
      isDisabled() {
        if (!this.selectedBroker || (this.selectedBroker && !this.selectedSubroutines.length)) {
          return true;
        }

        for (const subroutine of this.selectedSubroutines) {
          for (const variable of this.subroutineVariables(subroutine)) {
            const value = Object.keys(this.formData).find((key) => key.startsWith(variable) && this.formData[key] !== '');

            if (!value) {
              return true;
            }
          }
        }

        return false;
      },
    },
    created() {
      /**
       * Get broker names when modal is created
       */
      this.getBrokers();
    },
    methods: {
      /**
       * Clear selected data and close the modal
       */
      handleClose() {
        this.selectedSubroutines = [];
        this.formData = {};
        this.selectedBroker = '';
        this.$emit('input', false);
      },
      /**
       * Returns an array of variables for each subroutine to be rendered as inputs
       *
       * @param {object} currentSubroutineName - The current subroutine name.
       * @returns {Array} List of found subroutine variables.
       */
      subroutineVariables(currentSubroutineName) {
        const variables = [];
        const { script } = this.subroutines.find((routine) => routine.name === currentSubroutineName);
        const matches = script.match(/{{[^{}]+}}/g);

        if (matches) {
          matches.forEach((match) => {
            const variable = match.replace(/{{|}}/g, '').trim();

            if (!variables.includes(variable)) {
              variables.push(variable);
            }
          });
        }

        return variables;
      },
      /**
       * With the prepared data, download the file
       */
      handleExport() {
        const dataStr = this.preparedData();
        const blob = new Blob([dataStr], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        const formattedDate = new Date().toISOString().slice(0, 10);
        const fileName = `${this.selectedBroker} ${formattedDate}.txt`;

        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        this.$message({
          showClose: true,
          duration: 3000,
          message: `${this.selectedBroker} macro successfully exported!`,
          type: 'success',
        });
        this.handleClose();
      },
      /**
       * Returns the "Prepare Workbook" script with inserted subroutine calls.
       *
       * @returns {string} The formatted "Prepare Workbook" script.
       */
      getPrepareWorkbookScript() {
        const prepareWorkbookScript = startSubroutines.find((s) => s.name === 'Prepare Workbook').script.trim();
        const extractName = (script) => script.match(/Sub\s+(\w+)\s*\(/)?.[1] || '';
        const generateCall = (subs) => subs.map((sub) => `\tCall ${extractName(sub.script)}`).join('\n');
        const allCalls = [
          generateCall(startSubroutines.filter((s) => s.name !== 'Prepare Workbook')),
          generateCall(this.selectedSubroutines.map((name) => this.subroutines.find((s) => s.name === name))),
          generateCall(endSubroutines),
        ].join('\n');

        return prepareWorkbookScript.replace('{{CallLinePlaceholder}}', allCalls);
      },
      /**
       * Build an array that contains each of the selected subroutines with each variable replaced with
       * the value that the user entered by iterating over each line.
       *
       * @returns {string} Formatted string.
       */
      preparedData() {
        const result = [];

        // prepare workbook - insert call to all start subroutines (except 'Prepare Workbook'), selected subroutines, and end subroutines
        result.push(this.getPrepareWorkbookScript());

        // start by appending mandatory start subroutines except 'Prepare Workbook'
        startSubroutines.forEach((subroutine) => {
          if (subroutine.name !== 'Prepare Workbook') {
            result.push(subroutine.script.trim());
          }
        });

        this.selectedSubroutines.forEach((subroutineName) => {
          const subroutine = this.subroutines.find((routine) => routine.name === subroutineName);
          let { script } = subroutine;

          this.subroutineVariables(subroutineName).forEach((variable) => {
            const value = this.formData[`${variable}_${subroutineName}`];
            const regex = new RegExp(`{{\\s*${variable.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\s*}}`, 'g');

            script = script.replace(regex, value !== undefined ? value : '');
          });

          result.push(script.trim());
        });

        // end by appending mandatory end subroutines
        endSubroutines.forEach((subroutine) => {
          result.push(subroutine.script.trim());
        });

        return result.join('\n\n');
      },
      /**
       * Load broker names for selection for file export name.
       *
       */
      async getBrokers() {
        try {
          const response = await makeRequest('v1/admin/brokers', {}, 'GET');
          const data = response.data.brokers;

          this.brokers = data.map((broker) => broker.name);

          if (!response.status === 200) {
            this.displayToast({
              message: 'An error occurred trying to fetch the brokers.',
              type: 'error',
            });
          }
        } catch (error) {
          this.displayToast({
            message: 'An error occurred trying to fetch the brokers.',
            type: 'error',
          });
        }
      },
    },
  };
</script>

<style scoped lang="scss">
:deep(.el-dialog) {
  padding: 24px;
  width: 60%;
  border-radius: 10px;
  bottom: 5%;
}

.selection-content {
  max-height: 50vh;
  overflow-y: auto;
  padding: 10px 0;
}

.checkbox-container {
  margin-top: 20px;
  max-width: 350px;
}

.variable-container {
  margin: 10px;
  margin-bottom: 20px;
}

.description {
  margin-bottom: 25px;
  margin-top: -20px;
}

.variable-form {
  display: flex;
  flex-wrap: wrap;
}

.variable-form .el-form-item {
  flex: 1 1 30%;
  padding: 0 10px;
  max-width: 300px;
}

@media (max-width: 1200px) {
  ::v-deep .el-dialog {
    width: 70%;
  }
}

@media (max-width: 1000px) {
  ::v-deep .el-dialog {
    width: 80%;
  }
}

:deep(.el-radio__label) {
  color: var(--tf-gray-dark);
}

:deep(.el-radio__input.is-checked .el-radio__inner) {
  border-color: var(--tf-primary);
}

:deep(.el-radio__inner::after) {
  background-color: var(--tf-primary);
}

.dropdown-container {
  margin-bottom: 20px;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: var(--tf-primary);
  border-color: var(--tf-primary);
}

:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
  color: var(--tf-primary);
}

:deep(.el-input__inner) {
  border-radius: 4px;
}

:deep(.el-form-item__label) {
  font-weight: 600;
}

</style>
