<template>
  <div>
    <h1>Normalized values</h1>
    <div class="normalized-values-table">
      <LfcApiTable
        v-bind="{
          tableData,
          tableMeta,
          apiMeta,
          pageLimit: recordsPerPage,
        }"
        show-classic-no-filter-table-style
        @getTableData="getNormalizedValues"
        @setPage="setPage"
      >
        <template #title>
          Normalized Values
        </template>
        <template #ctaHeader>
          <AppButton
            :is-disabled="isAddDisabled"
            size="text"
            type="primary"
            icon="fa-solid fa-plus"
            data-test="add normalized value button"
            @click="addRow"
            v-text="'Add normalized value'"
          />
        </template>
        <template #parentRow="{ rowData }">
          <NormalizedValueRow
            :row-data="rowData"
            @saveValue="saveValue"
            @editValue="editValue"
            @deleteValue="deleteValue"
            @cancelValue="cancelValue"
          />
        </template>
        <template #empty>
          There don't appear to be any normalized values.
        </template>
      </LfcApiTable>
    </div>
  </div>
</template>

<script>
  import {
    createNormalizedValue,
    deleteNormalizedValue,
    getNormalizedValues as getNormalizedValuesApi,
    updateNormalizedValue,
  } from '@/services/normalizedValues.js';
  import NormalizedValueRow from '@/components/NormalizedValues/NormalizedValueRow.vue';

  /**
   * Table of Normalized Values
   *
   * @vuedoc
   * @exports NormalizedValuesTable
   * @category Components
   */
  export default {
    name: 'NormalizedValuesTable',
    components: { NormalizedValueRow },
    data() {
      return {
        tableData: [],
        tableMeta: {
          columns: [
            {
              format: 'string',
              label: 'Product',
              prop: 'product_type_name',
              width: 130,
            },
            {
              format: 'string',
              label: 'Attribute',
              prop: 'plan_design_attribute_name',
              width: 130,
            },
            {
              format: 'string',
              label: 'Normalized Value',
              prop: 'value',
              width: 250,
            },
            {
              width: 130,
            },
            {
              width: 80,
            },
          ],
          emptyValue: ' ',
        },
        apiMeta: {},
        recordsPerPage: 20,
        currentPage: 1,
      };
    },
    computed: {
      /**
       * Disable the add button if there is a new row already
       *
       * @returns {boolean}
       */
      isAddDisabled() {
        return this.tableData.some(({ id }) => !id);
      },
    },
    methods: {
      /**
       * Throw a generic message if the API doesn't have one on the response
       *
       * @param {object} error
       * @param {string} genericMessage
       *
       * @returns {string}
       */
      getErrorMessage(error, genericMessage) {
        const useGenericMessage = !error.message || (Array.isArray(error.message) && error.message.length === 0);

        if (!useGenericMessage) {
          return Array.isArray(error.message)
            ? error.message[0]
            : error.message;
        }

        return genericMessage;
      },
      /**
       * Get normalized values
       *
       * @param {object} obj
       * @param {number} obj.pageSelected
       * @param {string} obj.sortDirection
       * @param {string} obj.sortProp
       */
      getNormalizedValues({ pageSelected, sortDirection, sortProp }) {
        getNormalizedValuesApi({
          page: pageSelected,
          count: this.recordsPerPage,
          sortDirection,
          sortProp,
        })
          .then(({ normalized_values: values, _meta_data: meta }) => {
            this.$set(this, 'tableData', values);
            this.$set(this, 'apiMeta', meta);
          })
          .catch((error) => {
            this.displayToast({
              message: this.getErrorMessage(error, 'There was an error loading the normalized values.'),
            });
          });
      },
      /**
       * Add a new normalized value to tableData
       */
      addRow() {
        this.$set(this, 'tableData', [
          { id: null },
          ...this.tableData,
        ]);
      },
      /**
       * Set a row to isEditing
       *
       * @param {number} rowId
       */
      editValue(rowId) {
        this.$set(this.tableData.find(({ id }) => id === rowId), 'isEditing', true);
      },
      /**
       * Save new value
       *
       * @param {object} normalizedValue
       * @param {number} normalizedValue.id
       * @param {number} normalizedValue.productTypeId
       * @param {number} normalizedValue.planDesignAttributeId
       * @param {string} normalizedValue.value
       */
      async saveValue({
        id,
        productTypeId,
        planDesignAttributeId,
        value,
      }) {
        if (id) {
          try {
            const updatedValue = await updateNormalizedValue({
              id,
              value,
            });
            const valueIndex = this.tableData.findIndex(({ id: valueId }) => updatedValue.id === valueId);

            this.$set(this.tableData, valueIndex, {
              ...this.tableData[valueIndex],
              ...updatedValue,
              isEditing: false,
            });

            this.displayToast({
              message: 'Value updated successfully.',
              type: 'success',
            });
          } catch (error) {
            this.displayToast({
              message: this.getErrorMessage(error, 'There was an error updating the value.'),
            });
          }
        } else {
          try {
            await createNormalizedValue({
              productTypeId,
              planDesignAttributeId,
              value,
            });

            this.displayToast({
              message: 'Value created successfully.',
              type: 'success',
            });
            this.getNormalizedValues({ pageSelected: this.currentPage });
          } catch (error) {
            this.displayToast({
              message: this.getErrorMessage(error, 'There was an error creating the value.'),
            });
          }
        }
      },
      /**
       * Delete a value
       *
       * @param {number} valueId
       */
      deleteValue(valueId) {
        const normalizedValue = this.tableData.find(({ id }) => id === valueId);

        deleteNormalizedValue(valueId)
          .then(() => {
            this.displayToast({
              message: `${normalizedValue.product_type_name}/${normalizedValue.plan_design_attribute_name}/${normalizedValue.value} deleted successfully`,
              type: 'success',
            });
            this.getNormalizedValues({ pageSelected: this.currentPage });
          })
          .catch((error) => {
            this.displayToast({
              message: this.getErrorMessage(error, 'There was an error deleting the value.'),
            });
          });
      },
      /**
       * Cancel button on a row clicked
       * If there is no row id, remove the incomplete, new row from tableData
       * If there is an id, set the row's isEditing property to false
       *
       * @param {number} rowId
       */
      cancelValue(rowId) {
        if (!rowId) {
          this.$set(this, 'tableData', this.tableData.filter(({ id }) => !!id));
        } else {
          this.$set(this.tableData.find(({ id }) => id === rowId), 'isEditing', false);
        }
      },
      /**
       * Store the current page from the pagination component so that we can reload the data after adding a new value
       *
       * @param {number} page
       */
      setPage(page) {
        this.currentPage = page;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .normalized-values-table {
    padding: 0 30px;
    max-width: 1200px;
    margin: 0 auto;
  }

  h1 {
    background-color: var(--tf-gray-light);
    padding: 10px 30px;
    border-bottom: 1px solid var(--tf-gray-medium);
  }

  .pagination {
    margin: 10px 0;
  }
</style>
