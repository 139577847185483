<template>
  <div id="global-header">
    <RouterLink
      data-test-link-dashboard
      :to="{ name: 'Dashboard' }"
    >
      <div
        class="small-tf-logo"
        data-test="home button"
      >
        ThreeFlow
      </div>
    </RouterLink>
    <h2
      v-if="currentProject"
      v-text="currentProject.employer.name"
    />
    <nav>
      <div
        v-if="currentProject"
        class="btn-group"
      >
        <RouterLink
          data-test="link manage rfp"
          :to="{
            name: 'ManageRFP',
            props: { projectId },
          }"
        >
          <AppButton
            icon="fa-solid fa-cog"
            size="text"
            type="primary"
            data-test="manage rfp button"
            text="Manage RFP"
          />
        </RouterLink>
        <AppButton
          data-test="link broker preview"
          :href="`${url}/projects/${projectId}/?p=${selectedProjectProductId}`"
          icon="fa-solid fa-external-link-alt"
          size="text"
          tag="a"
          :is-disabled="!previewAsBrokerEnabled"
          target="_blank"
          rel="noopener noreferrer"
          type="primary"
          text="Preview as broker"
          @click="previewBrokerView($event)"
        />
        <AppButton
          data-test="open tactic engine"
          icon="fa-solid fa-wand-magic-sparkles"
          size="text"
          type="primary"
          :is-disabled="!activeFileIsSource"
          text="Tactic Engine"
          @click="setPanel('tactic-engine')"
        />
      </div>
      <ElMenu
        ref="main-menu"
        class="main-hamburger"
        :router="true"
        :default-active="activeIndex"
        menu-trigger="click"
        mode="horizontal"
      >
        <TfHamburgerMenu
          data-test="global navigation"
          @hide="closeHamburgerMenu"
        >
          <TfHamburgerMenuItem
            v-for="item in authenticatedHamburgerItems"
            :key="item.text"
            :index="!currentProject ? `/${item.name}` : ''"
            :to="{ name: item.name }"
            :title="item.text"
            type="parent"
          />
        </TfHamburgerMenu>
      </ElMenu>
    </nav>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'pinia';
  import { usePanelsStore } from '@/stores/panels.js';
  import { useUserInfoStore } from '@/stores/userInfo.js';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProjectStore } from '@/stores/project.js';
  import { config } from '@/utils/config.js';
  import { adminUiCarrierManagement, adminUiDiveManagement } from '@/utils/featureFlags.js';

  /**
   * Header/Navigation Component
   *
   * @vuedoc
   * @exports AdminHeader
   * @category Components
   */
  export default {
    name: 'AdminHeader',
    provide() {
      return {
        menu: this,
      };
    },
    data: () => ({
      url: config.VUE_APP_BROKER_URL,
    }),
    computed: {
      ...mapState(useProjectProductStore, [
        'selectedProjectProductId',
        'validPlanDesign',
        'validRateStructure',
      ]),
      ...mapState(useProjectStore, ['currentProject']),
      ...mapState(useUserInfoStore, { userRoles: 'roles' }),
      ...mapState(useFileViewerStore, ['getActiveFileId']),
      /**
       * Evaluate the "adminUiCarrierManagement" feature flag.
       *
       * @returns {boolean}
       */
      adminUiCarrierManagement() {
        return this.$ld.checkFlags(adminUiCarrierManagement);
      },
      /**
       * Evaluate the "adminUiDiveManagement" feature flag.
       *
       * @returns {boolean}
       */
      adminUiDiveManagement() {
        return this.$ld.checkFlags(adminUiDiveManagement);
      },
      /**
       * Get an array of all the hamburger menu items
       * NOTE: this can go back into data: () after the feature flag for Carrier Management is removed
       *
       * @returns {Array}
       */
      hamburgerItems() {
        return [
          { name: 'Dashboard', text: 'Main dashboard' },
          { name: 'BrokerManagement', text: 'Broker management' },
          ...(this.adminUiCarrierManagement ? [{ name: 'CarrierManagement', text: 'Carrier management' }] : []),
          ...(this.adminUiDiveManagement ? [{ name: 'DiveManagement', text: 'SP extraction', role: 'super_admin' }] : []),
          { name: 'NormalizedValuesManagement', text: 'Normalized values', role: 'super_admin' },
          { name: 'SupportTools', text: 'Support tools' },
          { name: 'SignOutPage', text: 'Sign out' },
        ];
      },
      /**
       * Filter hamburger menu items by role
       *
       * @returns {Array}
       */
      authenticatedHamburgerItems() {
        return this.hamburgerItems.filter(({ role }) => !role || this.userRoles.includes(role));
      },
      /**
       * Get the active route
       *
       * @returns {number}
       */
      activeIndex() {
        return window.location.pathname;
      },
      /**
       * Determine if project has been validated
       *
       * @returns {boolean}
       */
      projectValidated() {
        return this.validPlanDesign && this.validRateStructure;
      },
      /**
       * Gets the project ID of the current project
       *
       * @returns {number}
       */
      projectId() {
        return this.currentProject ? this.currentProject.id : null;
      },
      /**
       * Determine if active file is a PDF (has a numeric ID)
       *
       * @returns {boolean}
       */
      activeFileIsSource() {
        return !!Number(this.getActiveFileId);
      },
      /**
       * Determine if preview as broker should be enabled for policy audit project types or non-policy audit project types that are validated.
       *
       * @returns {number}
       */
      previewAsBrokerEnabled() {
        return this.currentProject.type === 'Projects::PolicyAudit'
          || (this.currentProject.type !== 'Projects::PolicyAudit' && this.projectValidated);
      },
    },
    methods: {
      ...mapActions(usePanelsStore, ['setPanel']),
      /**
       * Close hamburger menu
       */
      closeHamburgerMenu() {
        this.$refs['main-menu'].closeMenu('1');
      },
      /**
       * Prevent Broker link if not validated
       *
       * @param {Event} event
       */
      previewBrokerView(event) {
        if (!this.previewAsBrokerEnabled) {
          this.displayToast({
            message: 'You must validate your plan/rate first.',
            type: 'warning',
          });
          event.preventDefault();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
#global-header {
  height: 57px;
  border-bottom: 1px solid var(--tf-gray-light-medium);
  background: #fff;

  .management-page & {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
}

:deep(.el-menu--horizontal.el-menu) {
  border-bottom: 0;
}

.main-hamburger {
  :deep(.el-submenu.is-active .el-submenu__title) {
    border-bottom: 0;
  }
}

#global-header,
nav {
  display: flex;
  align-items: center;
}

nav {
  margin-left: auto;
}

.btn-group {
  margin-right: 20px;

  a,
  span {
    margin-left: var(--tf-btn-margin-medium);
  }
}

.small-tf-logo {
  height: 50px;
  width: 42px;
  margin: 0 20px;
  background: {
    repeat: no-repeat;
    position: center 10px;
    size: 24px;
  }
}
</style>
