import BrokerManagement from '@/views/BrokerManagement/index.vue';
import Dashboard from '@/views/Dashboard/index.vue';
import DashboardCompleted from '@/views/Dashboard/Completed.vue';
import DashboardClosed from '@/views/Dashboard/Closed.vue';
import DashboardLive from '@/views/Dashboard/Live.vue';
import DashboardOwner from '@/views/Dashboard/Owner.vue';
import DashboardSetup from '@/views/Dashboard/Setup.vue';
import AddBroker from '@/views/BrokerManagement/Broker/Add.vue';
import EditBroker from '@/views/BrokerManagement/Broker/Edit.vue';
import BrokerUserInvites from '@/views/BrokerManagement/Broker/BrokerUserInvites.vue';
import BrokerInviteUsers from '@/views/BrokerManagement/Broker/BrokerInviteUsers.vue';
import EditBrokerUsers from '@/views/BrokerManagement/Broker/Users.vue';
import EditBrokerInvitableCarriers from '@/views/BrokerManagement/Broker/InvitableCarriers.vue';
import EditBrokerDomains from '@/views/BrokerManagement/Broker/Domains.vue';
import EditBrokerSettings from '@/views/BrokerManagement/Broker/Settings.vue';
import SignInPage from '@/views/auth/SignIn.vue';
import SignOutPage from '@/views/auth/SignOut.vue';
import ManageRFP from '@/views/ManageRfp.vue';
import NormalizedValuesManagement from '@/views/NormalizedValuesManagement.vue';
import SupportTools from '@/views/SupportTools.vue';
import NotFound from '@/views/NotFound.vue';
import ProjectSetup from '@/views/ProjectSetup.vue';

// Carrier Management views
import CarrierManagement from '@/views/CarrierManagement/index.vue';
import AddCarrier from '@/views/CarrierManagement/Carrier/Add.vue';
import EditCarrier from '@/views/CarrierManagement/Carrier/Edit.vue';
import EditCarrierUsers from '@/views/CarrierManagement/Carrier/Users.vue';
import EditCarrierSettings from '@/views/CarrierManagement/Carrier/Settings.vue';
import EditCarrierDomains from '@/views/CarrierManagement/Carrier/Domains.vue';
import EditCarrierProductTypes from '@/views/CarrierManagement/Carrier/ProductTypes.vue';
import EditUser from '@/views/CarrierManagement/EditUser.vue';
import DiveManagement from '@/views/DiveManagement.vue';

/**
 * Direct user to sign in page depending on if user is authenticated and confirmed
 *
 * @param {object} to
 * @param {object} from
 * @param {Function} next
 */
function requireAuth(to, from, next) {
  const auth = window.localStorage.getItem('auth') === 'true';

  if (auth) {
    next();
  } else {
    next({
      path: '/sign-in',
      query: { redirect: to.fullPath },
    });
  }
}
/**
 * Direct user to next path depending on if user is Authenticated
 *
 * @param {object} to
 * @param {object} from
 * @param {Function} next
 */
function requireNotAuth(to, from, next) {
  const auth = window.localStorage.getItem('auth') === 'true';

  if (auth) {
    next({
      path: '/dashboard',
    });
  } else {
    next();
  }
}

export const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Dashboard' },
  },
  {
    path: '/sign-in',
    alias: '/login',
    name: 'SignInPage',
    component: SignInPage,
    beforeEnter: requireNotAuth,
    meta: { pageType: 'authentication' },
  },
  {
    path: '/sign-out',
    alias: '/logout',
    name: 'SignOutPage',
    component: SignOutPage,
    beforeEnter: requireAuth,
    meta: { pageType: 'authentication' },
  },
  {
    path: '/brokers',
    name: 'BrokerManagement',
    component: BrokerManagement,
    beforeEnter: requireAuth,
    meta: { pageType: 'management' },
  },
  {
    path: '/brokers/new',
    name: 'AddBroker',
    props: true,
    component: AddBroker,
    beforeEnter: requireAuth,
    meta: { pageType: 'management' },
    redirect: { name: 'AddBrokerSettings' },
    children: [
      {
        path: 'settings',
        name: 'AddBrokerSettings',
        component: EditBrokerSettings,
        beforeEnter: requireAuth,
        meta: { pageType: 'management' },
      },
    ],
  },
  {
    path: '/brokers/:brokerId',
    name: 'EditBroker',
    props: true,
    component: EditBroker,
    beforeEnter: requireAuth,
    meta: { pageType: 'management' },
    redirect: { name: 'EditBrokerUsers' },
    children: [
      {
        path: 'users/:userId?',
        name: 'EditBrokerUsers',
        component: EditBrokerUsers,
        /**
         * the props to pass to this route rendered component
         *
         * @param {object} route
         * @returns {object} the props that will be passed to the rendered component
         */
        props(route) {
          return {
            brokerId: route.params.brokerId,
            userId: route.params.userId,
          };
        },
        beforeEnter: requireAuth,
        meta: { pageType: 'management' },
      },
      {
        path: 'invite',
        name: 'BrokerInviteUsers',
        /**
         * the props to pass to this route rendered component
         *
         * @param {object} route
         * @returns {object} the props that will be passed to the rendered component
         */
        props(route) {
          return {
            brokerId: route.params.brokerId,
            showBreadcrumb: false,
          };
        },
        component: BrokerInviteUsers,
        beforeEnter: requireAuth,
        meta: { pageType: 'management' },
      },
      {
        path: 'invites',
        name: 'BrokerUserInvites',
        /**
         * the props to pass to this route rendered component
         *
         * @param {object} route
         * @returns {object} the props that will be passed to the rendered component
         */
        props(route) {
          return {
            brokerId: route.params.brokerId,
            showBreadcrumb: false,
          };
        },
        component: BrokerUserInvites,
        beforeEnter: requireAuth,
        meta: { pageType: 'management' },
      },
      {
        path: 'carriers',
        name: 'EditBrokerInvitableCarriers',
        component: EditBrokerInvitableCarriers,
        beforeEnter: requireAuth,
        meta: { pageType: 'management' },
        props: true,
      },
      {
        path: 'domains',
        name: 'EditBrokerDomains',
        component: EditBrokerDomains,
        beforeEnter: requireAuth,
        meta: { pageType: 'management' },
        props: true,
      },
      {
        path: 'settings',
        name: 'EditBrokerSettings',
        component: EditBrokerSettings,
        beforeEnter: requireAuth,
        meta: { pageType: 'management' },
        props: true,
      },
      {
        path: 'hierarchy',
        name: 'EditBrokerHierarchy',
        component: () => import('@/views/BrokerManagement/Broker/Hierarchy.vue'),
        beforeEnter: requireAuth,
        meta: { pageType: 'management' },
        props: true,
      },
    ],
  },
  {
    path: '/carrier-management',
    name: 'CarrierManagement',
    component: CarrierManagement,
    meta: {
      pageType: 'management',
    },
  },
  {
    path: '/carrier-management/new',
    name: 'AddCarrier',
    props: true,
    component: AddCarrier,
    meta: {
      pageType: 'management',
    },
    redirect: { name: 'AddCarrierSettings' },
    children: [
      {
        path: 'settings',
        name: 'AddCarrierSettings',
        component: EditCarrierSettings,
        meta: { pageType: 'management' },
      },
    ],
  },
  {
    path: '/carrier-management/:carrierId',
    name: 'EditCarrier',
    props: true,
    component: EditCarrier,
    meta: {
      pageType: 'management',
    },
    redirect: { name: 'EditCarrierUsers' },
    children: [
      {
        path: 'users',
        name: 'EditCarrierUsers',
        component: EditCarrierUsers,
        meta: { pageType: 'management' },
        props: true,
      },
      {
        path: 'users/new',
        name: 'AddCarrierUser',
        /**
         * the props to pass to this route rendered component
         *
         * @param {object} route
         * @returns {object} the props that will be passed to the rendered component
         */
        props(route) {
          return {
            orgId: route.params.carrierId,
            showBreadcrumb: false,
            userType: 'carrier',
          };
        },
        component: EditUser,
        meta: { pageType: 'management' },
      },
      {
        path: 'product_types',
        name: 'EditCarrierProductTypes',
        component: EditCarrierProductTypes,
        meta: { pageType: 'management' },
        props: true,
      },
      {
        path: 'domains',
        name: 'EditCarrierDomains',
        component: EditCarrierDomains,
        meta: { pageType: 'management' },
        props: true,
      },
      {
        path: 'settings',
        name: 'EditCarrierSettings',
        component: EditCarrierSettings,
        meta: { pageType: 'management' },
        props: true,
      },
      {
        path: 'users/:userId',
        name: 'EditCarrierUser',
        /**
         * the props to pass to this route rendered component
         *
         * @param {object} route
         * @returns {object} the props that will be passed to the rendered component
         */
        props(route) {
          return {
            orgId: route.params.carrierId,
            showBreadcrumb: false,
            userId: route.params.userId,
            userType: 'carrier',
          };
        },
        component: EditUser,
        meta: { pageType: 'management' },
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: requireAuth,
    meta: { requireAuth: true },
    redirect: '/dashboard/owner',
    children: [
      {
        path: 'owner',
        name: 'DashboardOwner',
        component: DashboardOwner,
        beforeEnter: requireAuth,
        meta: { requireAuth: true },
      },
      {
        path: 'setup',
        name: 'DashboardSetup',
        component: DashboardSetup,
        beforeEnter: requireAuth,
        meta: { requireAuth: true },
      },
      {
        path: 'live',
        name: 'DashboardLive',
        component: DashboardLive,
        beforeEnter: requireAuth,
        meta: { requireAuth: true },
      },
      {
        path: 'completed',
        name: 'DashboardCompleted',
        component: DashboardCompleted,
        beforeEnter: requireAuth,
        meta: { requireAuth: true },
      },
      {
        path: 'closed',
        name: 'DashboardClosed',
        component: DashboardClosed,
        beforeEnter: requireAuth,
        meta: { requireAuth: true },
      },
    ],
  },
  {
    path: '/rfp/:projectId',
    name: 'ManageRFP',
    component: ManageRFP,
    props: true,
    beforeEnter: requireAuth,
    meta: { requireAuth: true },
  },
  {
    path: '/project/:projectId/:projectProductId?',
    name: 'ProjectSetup',
    component: ProjectSetup,
    props: true,
    beforeEnter: requireAuth,
    meta: { requireAuth: true },
    alias: '/projects/:projectId/:projectProductId?',
  },
  {
    path: '/normalized-values',
    name: 'NormalizedValuesManagement',
    component: NormalizedValuesManagement,
    beforeEnter: requireAuth,
    meta: { pageType: 'management' },
  },
  {
    path: '/support-tools',
    name: 'SupportTools',
    component: SupportTools,
    beforeEnter: requireAuth,
    meta: { pageType: 'management' },
  },
  {
    path: '/dive-filter-management',
    name: 'DiveManagement',
    component: DiveManagement,
    beforeEnter: requireAuth,
    meta: { pageType: 'management' },
  },
  {
    path: '*',
    component: NotFound,
  },
];
