import axios from 'axios';
import { cloneDeep, uniq } from 'lodash';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { useProjectProductStore } from '@/stores/projectProduct.js';
import { useProductStructuresStore } from '@/stores/productStructures.js';
import { useProductAttributesStore } from '@/stores/productAttributes.js';
import { config } from '@/utils/config.js';

const apiUrl = config.VUE_APP_API_URL;
const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;

/**
 * Product API calls
 *
 * @exports Product
 * @category Services
 */

/**
 * Get all the different product types from the DB
 *
 * @returns {Promise}
 */
export async function getProductTypes() {
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const url = `${apiUrl}/v1/admin/product_types`;

  try {
    const { data } = await axios
      .get(url, axiosConfig);

    return data;
  } catch (error) {
    return error;
  }
}
/**
 * Get plan design attributes for a specific product type
 *
 * @param {number} productTypeId
 * @returns {Promise}
 */
export async function getProductTypePlanDesignAttributeDiveExclusions(productTypeId) {
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const url = `${apiUrl}/v1/admin/product_types/${productTypeId}/plan_design_attributes`;

  try {
    const { data } = await axios.get(url, axiosConfig);

    return data;
  } catch (error) {
    return error;
  }
}

/**
 * Update DIVE exclusion status for a plan design attribute
 *
 * @param {number} productTypeId
 * @param {number} planDesignAttributeId
 * @param {boolean} diveExcluded
 * @returns {Promise}
 */
export async function updatePlanDesignAttributeDiveExclusion(productTypeId, planDesignAttributeId, diveExcluded) { /// diveExcluses how does this work
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const url = `${apiUrl}/v1/admin/product_types/${productTypeId}/plan_design_attributes/${planDesignAttributeId}/dive_exclusion`;

  try {
    const { data } = await axios.patch(url, { dive_excluded: diveExcluded }, axiosConfig);

    return data;
  } catch (error) {
    return error;
  }
}
/**
 * Get all annotations for a product
 *
 * @param {number} productId
 * @returns {Promise}
 */
export async function getProductAnnotations(productId) {
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const url = `${apiUrl}/v1/admin/products/${productId}/annotations`;

  try {
    const { data } = await axios
      .get(url, axiosConfig);

    return data.product;
  } catch (error) {
    return error;
  }
}

export default {
  /**
   * Reassigns the wt_owner of a product
   *
   * @param {number} productId
   * @param {number} userId
   * @returns {Promise}
   */
  changeProductOwner(productId, userId) {
    const axiosConfig = getAxiosConfig(cookieNameSpace);
    const url = `${apiUrl}/v1/admin/products/${productId}/assign_owner`;

    return new Promise((resolve, reject) => {
      axios.patch(url, userId, axiosConfig)
        .then(() => resolve())
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Get all the attributes for a product
   *
   * @param {number} productId
   * @returns {Promise}
   */
  getProductDetails(productId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/products/${productId}`;

      axios
        .get(url, axiosConfig)
        .then(({ data }) => {
          resolve(data.product);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Get all the different product types from the DB
   *
   * @param {number} productTypeId
   * @returns {Promise}
   */
  getAvailableTierGroups(productTypeId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/product_types/${productTypeId}/tier_groups`;

      axios
        .get(url, axiosConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Given a product type id, get the list of carriers that offer that product
   *
   * @param {number} productTypeId
   * @returns {object}
   */
  async getProductTypeOfferingCarriers(productTypeId) {
    const axiosConfig = getAxiosConfig(cookieNameSpace);
    const url = `${apiUrl}/v1/admin/product_types/${productTypeId}/offering_carriers`;

    try {
      const { data } = await axios.get(url, axiosConfig);

      return data;
    } catch (error) {
      return error;
    }
  },
  /**
   * Create a new product on a document
   *
   * @param {number} documentID
   * @param {object} data
   * @param {boolean} alternative
   * @returns {object}
   */
  async createNewProduct(documentID, data, alternative = false) {
    const appendUrl = alternative ? 'alternative_products' : 'products';

    try {
      const { data: { product } } = await axios.post(
        `${apiUrl}/v1/admin/documents/${documentID}/${appendUrl}`,
        data,
        getAxiosConfig(cookieNameSpace),
      );

      return product;
    } catch (error) {
      return error;
    }
  },
  /**
   * Delete specific container based on the container ID.
   *
   * @param {object} container
   * @param {number} container.containerId
   * @returns {Promise}
   */
  async deleteContainer({ containerId }) {
    const { data } = await axios
      .delete(
        `${apiUrl}/v1/admin/project_products_containers/${containerId}`,
        getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE),
      );

    return data;
  },
  /**
   * Update specific container with the data object based on container ID.
   *
   * @param {object} options
   * @param {number} options.containerId
   * @param {object} options.data
   * @returns {Promise}
   */
  async updatePlanContainer({ containerId, data: payload }) {
    const { data } = await axios.patch(
      `${apiUrl}/v1/admin/project_products_containers/${containerId}`,
      payload,
      getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE),
    );

    return data;
  },
  /**
   * This also adds a local description and container type ID for updating in components.
   *
   * @param {object} options
   * @param {object} options.data
   * @param {number} options.productId
   * @returns {Promise}
   */
  async addProductContainer({ data: payload, productId }) {
    const { data } = await axios
      .post(
        `${apiUrl}/v1/admin/products/${productId}/containers`,
        payload,
        getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE),
      );

    return data;
  },
  /**
   * Update values on product attributes.
   * IF `updatePlanValue` is passed: Update just the plan.values[valueIndex] for the plan instead of
   * the entire attribute that is returned in the store. `attributeIndex`, `categoryIndex`,
   * `planIndex`, and `valueIndex` are required for this to work properly.
   *
   * @param {object} options
   * @param {object} options.annotation
   * @param {number} options.attributeId
   * @param {number} options.attributeIndex
   * @param {number} options.categoryIndex
   * @param {boolean} options.createPlansFromActiveStoreProduct
   * @param {Array} options.containerIds
   * @param {number} options.planIndex
   * @param {number} options.productId
   * @param {number} options.subtypeId
   * @param {number} options.tierGroupId
   * @param {boolean} options.updatePlanAttribute
   * @param {number} options.updatePlanValue
   * @param {string} options.value
   * @param {number} options.valueIndex
   * @returns {Promise}
   */
  async updatePlanValue({
    annotation = null,
    attributeId,
    attributeIndex = null,
    categoryIndex = null,
    createPlansFromActiveStoreProduct = false,
    containerIds,
    planIndex = null,
    productId,
    subtypeId,
    tierGroupId,
    updatePlanAttribute = true,
    updatePlanValue = false,
    value,
    valueIndex = null,
  }) {
    const url = `${apiUrl}/v1/admin/plan_design_attributes/${attributeId}/plan_design_values`;
    const payload = {
      plan_design_attribute_id: attributeId,
      product_id: productId,
      project_products_container_ids: containerIds,
      tier_group_id: tierGroupId,
      tier_subtype_id: subtypeId,
      value: value || '',
    };
    const productStructuresStore = useProductStructuresStore();

    if (annotation) {
      payload.annotation = annotation;
    }
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

    try {
      const { data } = await axios.patch(url, payload, axiosConfig);

      /* Talk to BE team about seeing if we can return just the value that was updated. Maybe we
      * can base it off of a `returnValue query string or something.` */
      if (updatePlanValue) {
        const planDesignValue = data.plan_design_attribute.grouped_values
          .find((valueObj) => valueObj.tier_group_id === tierGroupId
            && valueObj.tier_subtype_id === subtypeId
            && valueObj.project_products_container_ids.includes(containerIds[0]));
        const addOrSetPlanDesignValue = valueIndex === null
          ? productStructuresStore.addPlanDesignValues
          : productStructuresStore.setPlanDesignValues;

        addOrSetPlanDesignValue({
          attributeIndex,
          categoryIndex,
          planDesignValue,
          planIndex,
          tierGroupId,
          valueIndex,
        });
      } else if (updatePlanAttribute) {
        const productAttributesStore = useProductAttributesStore();

        productAttributesStore.updatePlanAttribute({
          updatedAttribute: data.plan_design_attribute,
          attributeIndex,
          categoryIndex,
          createPlansFromActiveStoreProduct,
        });
      }
      const projectProductStore = useProjectProductStore();

      projectProductStore.updateProjectProductsValidation({
        productId,
        validPlanDesign: false,
      });

      return data;
    } catch (error) {
      return error;
    }
  },
  /**
   * Delete values on product attributes.
   * IF a `valueIndex` (0 counts) is passed: Delete the value for the plan.values[valueIndex] in the store.
   * `attributeIndex`, `categoryIndex`, `planIndex` are required to target the correct value.
   *
   * @param {object} options
   * @param {number} options.attributeId
   * @param {number} options.attributeIndex
   * @param {number} options.categoryIndex
   * @param {Array} options.containerIds
   * @param {number} options.planIndex
   * @param {number} options.productId
   * @param {number} options.subtypeId
   * @param {number} options.valueIndex
   * @returns {Promise}
   */
  async deletePlanDesignValue({
    attributeId,
    attributeIndex = null,
    categoryIndex = null,
    containerIds,
    planIndex = null,
    productId,
    subtypeId = null,
    valueIndex = null,
  }) {
    const url = `${apiUrl}/v1/admin/plan_design_attributes/${attributeId}/plan_design_values`;
    const axiosConfigData = {
      product_id: productId,
      project_products_container_ids: containerIds,
    };

    if (subtypeId) {
      axiosConfigData.tier_subtype_id = subtypeId;
    }
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

    axiosConfig.data = axiosConfigData;
    try {
      const { data } = await axios.delete(url, axiosConfig);
      const projectProductStore = useProjectProductStore();

      projectProductStore.updateProjectProductsValidation({
        productId,
        validPlanDesign: false,
      });
      if (valueIndex !== null) {
        const productStructuresStore = useProductStructuresStore();

        productStructuresStore.deletePlanDesignValueFromStructure({
          attributeIndex,
          categoryIndex,
          planIndex,
          valueIndex,
        });
      }

      return data;
    } catch (error) {
      return error;
    }
  },
  /**
   * Update values on product attributes
   *
   * @param {number} productId
   * @param {number} rateId
   * @param {Array} values
   * @param {number} tierGroupId
   * @param {number} subtypeId
   * @param {Array} containerIds
   * @param {string} rateBasis
   * @param {string} type
   * @returns {Promise}
   */
  updateRateValues(productId, rateId, values, tierGroupId, subtypeId, containerIds, rateBasis, type) {
    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/v1/admin/rate_attributes/${rateId}/rate_values`;
      const clonedValues = cloneDeep(values);

      clonedValues.forEach((value, valueIndex) => {
        if (value.value === '' || Number.isNaN(value.value)) {
          clonedValues[valueIndex].value = null;
        }
      });
      const axiosData = {
        product_id: productId,
        project_products_container_ids: containerIds,
        values: clonedValues,
        tier_group_id: tierGroupId,
        tier_subtype_id: subtypeId,
        rate_basis: rateBasis,
        type,
      };
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

      axios
        .patch(url, axiosData, axiosConfig)
        .then(({ data }) => {
          const projectProductStore = useProjectProductStore();

          projectProductStore.updateProjectProductsValidation({
            productId,
            validRateStructure: false,
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Delete values on product attributes
   *
   * @param {number} productId
   * @param {number} attributeId
   * @param {Array} containerIds
   * @param {number} subtypeId
   * @returns {Promise}
   */
  deleteRateValues(productId, attributeId, containerIds, subtypeId) {
    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/v1/admin/rate_attributes/${attributeId}/rate_values`;
      const axiosConfigData = {
        product_id: productId,
        project_products_container_ids: uniq(containerIds),
      };

      if (subtypeId) {
        axiosConfigData.tier_subtype_id = subtypeId;
      }
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

      axiosConfig.data = axiosConfigData;

      axios
        .delete(url, axiosConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Delete specific annotation
   *
   * @param {number} annotationId
   * @returns {Promise}
   */
  deleteAnnotation(annotationId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

      axios
        .delete(`${apiUrl}/v1/admin/smart_parser/annotations/${annotationId}`, axiosConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Delete a specific product
   *
   * @param {number} productId
   * @returns {Promise}
   */
  deleteProduct(productId) {
    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/v1/admin/products/${productId}`;
      const axiosConfig = getAxiosConfig(cookieNameSpace);

      axios
        .delete(url, axiosConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  /**
   * Update various details on a specific product
   *
   * @param {number} productId
   * @param {object} data
   * @returns {Promise}
   */
  async updateProductDetails(productId, data) {
    try {
      const { data: product } = await axios.patch(
        `${apiUrl}/v1/admin/products/${productId}`,
        data,
        getAxiosConfig(cookieNameSpace),
      );

      return product;
    } catch (error) {
      return error;
    }
  },
  /**
   * Attempt to update Product State to specific state
   *
   * @param {number} productId
   * @param {string} transition
   * @returns {Promise}
   */
  updateProductState(productId, transition) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${apiUrl}/v1/admin/products/${productId}/${transition}`,
          {},
          getAxiosConfig(cookieNameSpace),
        )
        .then(({ data: { product } }) => {
          resolve(product);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
