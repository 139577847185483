const startSubroutines = [
  {
    name: 'Prepare Workbook',
    script: `
        Sub PrepareWorkbook()
            Dim wb As Workbook
            Dim targetWorkbook As Workbook
            Dim targetFilePath As String
            Dim my_file As Variant
            Dim sheet As Worksheet
            
            my_file = Application.GetOpenFilename()
            
            Worksheets("Format Button").Cells(3, 11).Value = my_file
            
            Set targetWorkbook = ThisWorkbook
            
            targetFilePath = targetWorkbook.Sheets("Format Button").Range("K3").Value
            
            If Len(targetFilePath) = 0 Then
                MsgBox "File path is not specified.", vbExclamation
                Exit Sub
            End If
            
            Set wb = Workbooks.Open(my_file)
            
            {{CallLinePlaceholder}}
            
            Sheets("Title").Select
            
            MsgBox "Formatting complete."
        End Sub
    `,
  },
  {
    name: 'Start',
    script: `
        Sub Start()

            Application.ScreenUpdating = False
            Application.Calculation = xlCalculationManual
            Application.EnableEvents = False
            Application.DisplayAlerts = False

        End Sub
    `,
  },
  {
    name: 'Hide Sheets',
    script: `
        Sub HideSheets()
            Dim ws As Worksheet
            Dim wb As Workbook
            
            On Error Resume Next
            Set ws = wb.Sheets("Title")
            If Not ws Is Nothing Then
                ws.Visible = xlSheetHidden
            End If
            On Error GoTo 0
            
            On Error Resume Next
            Set ws = wb.Sheets("ThreeFlow Disclosures")
            If Not ws Is Nothing Then
                ws.Visible = xlSheetHidden
            End If
            On Error GoTo 0
        End Sub
    `,
  },
  {
    name: 'Insert and Hide Rows',
    script: `
        Sub InsertAndHideRow()
            Dim ws As Worksheet
            Dim wb As Workbook
            Dim targetCell As Range
            
            ' Loop through each worksheet in the workbook
            For Each ws In wb.Worksheets
                ' Look for "Annual Premium" in column A starting from row 1
                Set targetCell = ws.Columns("A").Find(What:="Annual Premium", LookIn:=xlValues, LookAt:=xlWhole)
                
                ' Check if targetCell is set and it's in cell A5
                If Not targetCell Is Nothing Then
                    If targetCell.Row = 5 Then
                        ' Insert a row above row 5
                        ws.Rows(5).Insert Shift:=xlDown, CopyOrigin:=xlFormatFromLeftOrAbove
                        ' Hide row 5
                        ws.Rows(5).Hidden = True
                    End If
                End If
            Next ws
        End Sub
    `,
  },
  {
    name: 'Remove Conditional Formatting',
    script: `
        Sub RemoveConditionalFormatting()
            Dim ws As Worksheet
            Dim wb As Workbook
            
            For Each ws In wb.Sheets
                ws.Cells.FormatConditions.Delete
            Next ws
        End Sub
    `,
  },
];
const endSubroutines = [
  {
    name: 'Unhide Sheets',
    script: `
        Sub UnhideSheets()
            Dim ws As Worksheet
            Dim wb As Workbook
            
            On Error Resume Next
            Set ws = wb.Sheets("Title")
            If Not ws Is Nothing Then
                ws.Visible = xlSheetVisible
            End If
            On Error GoTo 0
            
            
            On Error Resume Next
            Set ws = wb.Sheets("ThreeFlow Disclosures")
            If Not ws Is Nothing Then
                ws.Visible = xlSheetVisible
            End If
            On Error GoTo 0
        End Sub
    `,
  },
  {
    name: 'Apply Conditional Formatting',
    script: `
        Sub ApplyCF()
            Dim ws As Worksheet
            Dim wb As Workbook
            Dim rng As Range
            Dim cell As Range

        For Each ws In wb.Worksheets
                If ws.Visible = xlSheetVisible Then
                    Set found = ws.Columns("A").Find(What:="Annual Premium", LookIn:=xlValues, LookAt:=xlWhole)
                    If Not found Is Nothing Then
                        annualPremiumRow = found.Row
                        
                        lastCol = ws.Cells(annualPremiumRow, ws.Columns.Count).End(xlToLeft).Column
                        
                        For Each cell In ws.Range(ws.Cells(annualPremiumRow, 2), ws.Cells(annualPremiumRow, lastCol))
                            Set cf = cell.FormatConditions.Add(Type:=xlCellValue, Operator:=xlGreater, Formula1:="=$B$6")
                            With cf
                                .Interior.Color = RGB(226, 86, 69) ' Set fill color to red (#E25645)
                                .Font.Color = RGB(255, 255, 255) ' Set font color to white (#FFFFFF)
                            End With
                            
                            Set cf = cell.FormatConditions.Add(Type:=xlCellValue, Operator:=xlLess, Formula1:="=$B$6")
                            With cf
                                .Interior.Color = RGB(24, 174, 119) ' Set fill color to green (#18AE77)
                                .Font.Color = RGB(255, 255, 255) ' Set font color to white (#FFFFFF)
                            End With
                        Next cell
                    End If
                End If
            Next ws
            
            wb.Sheets("Title").Activate
            
        End Sub
    `,
  },
  {
    name: 'Finish',
    script: `
        Sub Finish()
        
        Application.ScreenUpdating = True
        Application.Calculation = xlCalculationAutomatic
        Application.EnableEvents = True
        Application.DisplayAlerts = True 

        End Sub
    `,
  },
];

export { startSubroutines, endSubroutines };
