<template>
  <tr class="cp-table__row" data-test="new product type row">
    <td>
      <ElSelect
        v-model="productTypeSelection"
        aria-label="select product types"
        data-test="edit product type"
        value-key="id"
        autocomplete
        filterable
        no-data-text="No product types found"
        no-match-text="Product type not found"
        placeholder="Select product type"
      >
        <ElOption
          v-for="productType in availableProductTypes"
          :key="productType.id"
          :value="productType"
          :label="productType.name"
          :data-test="`select ${productType.name} product type`"
        />
      </ElSelect>
    </td>
    <td>
      <ElSwitch
        v-model="newProductType.certified"
        data-test="toggle product certified"
      />
    </td>
    <td class="states-editing">
      <TfMultiSelect
        v-model="newProductType.state_ids"
        :append-to-body="true"
        label="name"
        :total-options-available="statesList.length"
        :options="statesList"
        empty-text="No states available"
        popper-class="states-multi-select-popover multi-select-popover"
        value-key="id"
        data-test="edit states"
      />
    </td>
    <td>
      <div class="btn-group align-end">
        <AppButton
          icon="fa-solid fa-times"
          size="icon"
          type="secondary"
          data-test="cancel new product type"
          @click="$emit('delete', rowKey)"
        />
        <AppButton
          icon="fa-solid fa-check"
          size="icon"
          type="affirm"
          :is-disabled="isDisabled"
          data-test="save new product type"
          @click="saveNewProductType"
        />
      </div>
    </td>
  </tr>
</template>

<script>
  /**
   * New Product Type line item
   *
   * @vuedoc
   * @exports NewProductType
   * @category Components
   */
  export default {
    name: 'NewProductType',
    props: {
      /**
       * An array of product types that are currently not assigned to the carrier.
       */
      availableProductTypes: {
        type: Array,
        required: true,
      },
      /**
       * Since we support multiple new entries, this key is used to tell the parent which item to remove after we cancel or save the product type.
       */
      rowKey: {
        type: Number,
        default: 0,
      },
      /**
       * An array of all states with the corresponding IDs for the back-end.
       */
      statesList: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      newProductType: {
        certified: false,
        product_type_id: null,
        state_ids: [],
      },
      productTypeSelection: null,
      isEditingStates: false,
    }),
    computed: {
      /**
       * Returns whether the save button is disabled
       *
       * @returns {boolean}
       */
      isDisabled() {
        return (!this.productTypeSelection || !this.productTypeSelection.id || !this.newProductType.state_ids.length);
      },
    },
    methods: {
      /**
       * Save the new product type to the carrier by emitting the new productType to the parent
       */
      saveNewProductType() {
        this.$emit('save', {
          rowKey: this.rowKey,
          newProductType: {
            ...this.newProductType,
            product_type_id: this.productTypeSelection.id,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cp-table__row {
    > td {
      align-items: center;
      margin: 20px;
    }
  }

  .states-editing {
    display: flex;
    justify-content: space-between;

    > span {
      flex: 1;
      margin-right: 10px;
    }
  }
</style>
<style>
  .states-multi-select-popover {
    height: 200px;
    overflow: scroll;
  }
</style>
