/**
 * Define default values for Feature Flags as a fallback in case Launch Darkly is down or a flag cannot be found
 * Feature Flags are kebab-cased keys by default in Launch Darkly but Vue-LD converts them to camel-cased keys
 * therefore the Feature Flag values below should be camel-cased
 * See this issue for details https://github.com/dashhudson/vue-ld/issues/22
 *
 * @exports FeatureFlags
 * @category Utils
 */

// rff:adminUiCarrierManagement https://watchtower.atlassian.net/browse/LC-2221
export const adminUiCarrierManagement = 'adminUiCarrierManagement';
// rff:adminUiDiveManagement https://watchtower.atlassian.net/browse/LC-2262
export const adminUiDiveManagement = 'adminUiDiveManagement';
// rff:adminUiRenewalShortcutSupport https://watchtower.atlassian.net/browse/LC-616
export const adminUiRenewalShortcutSupport = 'adminUiRenewalShortcutSupport';
// rff: https://watchtower.atlassian.net/browse/LC-833
export const npcDetails = 'npcDetails';
// rff:policyAuditFlag https://watchtower.atlassian.net/browse/LC-835
export const policyAuditFlag = 'policyAudit';
// rff:requireReasonForClosingProject https://watchtower.atlassian.net/browse/LC-837
export const requireReasonForClosingProject = 'requireReasonForClosingProject';
// rff:useIebTemplatesForAllPlanBasedProducts https://watchtower.atlassian.net/browse/LC-1338
export const useIebTemplatesForAllPlanBasedProducts = 'useIebTemplatesForAllPlanBasedProducts';
// rff:workOsSsoPhase2 https://watchtower.atlassian.net/browse/LC-2324
export const workOsSsoPhase2 = 'workOsSsoPhase2';

// These defaults will be set instead of the defaultFlagValues if a user is in the dev or test environment
// this allows developers to manually control feature toggles while developing or testing
export const developmentFlagValues = {
  [adminUiCarrierManagement]: true,
  [adminUiDiveManagement]: true,
  [adminUiRenewalShortcutSupport]: true,
  [npcDetails]: true,
  [policyAuditFlag]: true,
  [requireReasonForClosingProject]: false,
  [useIebTemplatesForAllPlanBasedProducts]: false,
  [workOsSsoPhase2]: true,
};

/* Default Launch Darkly Flags (for Production, Staging and Features or if Launch Darkly can't be reached)
  * Keys match the feature flag names and values match the actual default flag values that we want to set.
  * We will need to update this object each time a new flag is added to Launch Darkly
  * and also be sure to use safe defaults here in case we cannot communicate with Launch Darkly so that
  * we do not accidentally enable a feature in production.
  */
export const defaultFlagValues = {
  [adminUiCarrierManagement]: false,
  [adminUiDiveManagement]: false,
  [adminUiRenewalShortcutSupport]: false,
  [npcDetails]: true,
  [policyAuditFlag]: false,
  [requireReasonForClosingProject]: false,
  [useIebTemplatesForAllPlanBasedProducts]: false,
  [workOsSsoPhase2]: false,
};
